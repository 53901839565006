import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from "react"
import Status from '../../Components/Status';
import { Event } from '../Home';
import moment from 'moment';
import { Employee } from '../../App';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

interface Props {
    emps: Employee[]
    allEvents: Event[]
    viewing: boolean
    handleClose: () => void
    createNewUser: (emp: Employee) => void
    giveUserAccess: (emp: string, needsAccess: string) => void
    openModal: () => void
}

const SetEmp = ({ emps, viewing, handleClose, createNewUser, openModal, allEvents, giveUserAccess }: Props) => {
    const [startDate, setStartDate] = useState<Date | null>()
    const [fullName, setFullName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [approver, setApprover] = useState<string>("")
    const [entitlement, setEntitlement] = useState<number>(0)
    const [daysForService, setDaysForService] = useState<number>(0)
    const [xmas, setXmas] = useState<number>(0)
    const [daysToUse, setDaysToUse] = useState<number>(0)
    const [carryOver, setCarryOver] = useState<number>(0)
    const [inLieu, setInLieu] = useState<number>(0)
    const [editing, setEditing] = useState<boolean>(false)
    const [deleteItem, setDeleteItem] = useState<string>("")
    const [department, setDepartment] = useState<string>("")
    const [access, setAccess] = useState<string[]>([])
    const [tempAdd, setTempAdd] = useState<string>("")
    const [confirm, setConfirm] = useState<boolean>(false)

    const getDaysUsed = (emp: Employee) => {
        let total = 0
        allEvents.map((event) => {
            if (event.hrStatus === "Approved" && event.managerStatus === "Approved" && event.employee.email.toLowerCase() === emp.email.toLowerCase() && (event.type === "Annual Leave" || event.type === "Medical" || event.type === "In-Lieu")) {
                total = total + event.daysTaken
            }
        })
        total = total + emp.xmas
        return total
    }

    const rows: GridRowsProp = emps.map((emp) => {
        let usedDays = 0
        let remDays = 0
        allEvents.map((event) => {
            if (event.employee.email.toLowerCase() === emp.email.toLowerCase() && event.hrStatus === "Approved" && event.managerStatus === "Approved" && (event.type === "Annual Leave" || event.type === "Medical")) {
                usedDays = usedDays + event.daysTaken
            }
        })
        remDays = emp.entitlement + emp.daysForService - usedDays - emp.xmas
        return {
            id: emp.email,
            remaining: `${(emp.entitlement + emp.daysForService + emp.carryOver + emp.inLieu) - getDaysUsed(emp)} Days`,
            daysUsed: usedDays,
            department: emp.department || "",
            ...emp
        }
    })

    const columns: GridColDef[] = [
        { field: 'fullName', headerName: 'Full Name', width: 200 },
        { field: 'email', headerName: 'Email Address', width: 400 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'entitlement', headerName: 'Entitlement', width: 200 },
        { field: 'daysForService', headerName: 'Service Days', width: 200 },
        { field: 'xmas', headerName: 'Christmas', width: 200 },
        { field: 'carryOver', headerName: 'Carry Over', width: 200 },
        { field: 'daysUsed', headerName: 'Days Used', width: 200 },
        { field: 'remaining', headerName: 'Days Remaining', width: 200 },
        { field: 'access', headerName: 'Who Has Access', width: 600 },
    ];

    useEffect(() => {
        setDaysToUse((entitlement + daysForService + carryOver + inLieu) - xmas)
    }, [xmas, daysForService, entitlement, carryOver, inLieu])

    const createUser = () => {
        const temp: Employee = {
            email,
            approver,
            daysForService,
            daysToUse,
            xmas,
            fullName,
            department,
            startDate: moment(startDate).format("DD/MM/yyyy"),
            entitlement,
            access: [approver, ...access].filter((item, index) => [approver, ...access].indexOf(item) === index),
            carryOver,
            inLieu
        }
        temp.access.map((emp) => {
            giveUserAccess(temp.email, emp)
        })
        createNewUser(temp)
        handleClose()
        setFullName("")
        setEmail("")
        setApprover("")
        setEntitlement(0)
        setDaysForService(0)
        setXmas(0)
        setDaysToUse(0)
        setAccess([])
    }

    return (
        <Row className='pb-7'>
            <Modal show={viewing} onHide={() => {
                setEditing(false)
                handleClose()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-bold">{`${editing ? "Edit User" : "Add New User"}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="form-label">Full Name:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={fullName}
                            onChange={(value) => setFullName(value.currentTarget.value)}
                            placeholder="Enter full name..." />
                    </Form.Group>
                    <p className="form-label">Email Address:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={email}
                            onChange={(value) => setEmail(value.currentTarget.value)}
                            placeholder="Enter email address..." />
                    </Form.Group>
                    <p className="form-label">Manager Email Address:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={approver}
                            onChange={(value) => setApprover(value.currentTarget.value)}
                            placeholder="Enter manager email..." />
                    </Form.Group>
                    <p className="form-label">Department:</p>
                    <Form.Group controlId="raiseTicket.summary">
                        <Form.Select value={department} onChange={(e) => setDepartment(e.currentTarget.value)}>
                            <option>Select department...</option>
                            <option value="Contracts - Office">Contracts - Office</option>
                            <option value="Contracts - Site">Contracts - Site</option>
                            <option value="Design">Design</option>
                            <option value="Directors">Directors</option>
                            <option value="Finance & Warehouse">Finance & Warehouse</option>
                            <option value="Process">Process</option>
                            <option value="Remedials - FPS">Remedials - FPS</option>
                            <option value="Remedials - LPS">Remedials - LPS</option>
                            <option value="Rope Access">Rope Access</option>
                            <option value="Sales">Sales</option>
                            <option value="Test & Inspection Operations">Test & Inspection Operations</option>
                            <option value="Test & Inspection Sales">Test & Inspection Sales</option>
                            <option value="Test & Inspection Site">Test & Inspection Site</option>
                            <option value="Training & PPE">Training & PPE</option>
                        </Form.Select>
                    </Form.Group>
                    <p className="form-label">Start Date:</p>
                    <Form.Group className='mb-2'>
                        <ReactDatePicker
                            className="form-control"
                            wrapperClassName='w-100'
                            selected={startDate}
                            placeholderText="Select a start date..."
                            onChange={(date) => setStartDate(date)}
                        />
                    </Form.Group>
                    <p className="form-label">Entitlement:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={entitlement.toString()}
                            onChange={(value) => setEntitlement(parseFloat(value.currentTarget.value))}
                            step={0.5}
                            type='Number'
                            placeholder="Enter entitlement..." />
                    </Form.Group>
                    <p className="form-label">Days For Service:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={daysForService.toString()}
                            onChange={(value) => setDaysForService(parseFloat(value.currentTarget.value))}
                            step={0.5}
                            type='Number'
                            placeholder="Enter days for service..." />
                    </Form.Group>
                    <p className="form-label">Days For Christmas:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={xmas.toString()}
                            onChange={(value) => setXmas(parseFloat(value.currentTarget.value))}
                            step={0.5}
                            type='Number'
                            placeholder="Enter days for christmas..." />
                    </Form.Group>
                    <p className="form-label">Carry Over:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={carryOver.toString()}
                            onChange={(value) => setCarryOver(parseFloat(value.currentTarget.value))}
                            step={0.5}
                            type='Number'
                            placeholder="Enter carry over..." />
                    </Form.Group>
                    <p className="form-label">In Lieu:</p>
                    <Form.Group className='mb-2'>
                        <Form.Control
                            value={inLieu.toString()}
                            onChange={(value) => setInLieu(parseFloat(value.currentTarget.value))}
                            step={0.5}
                            type='Number'
                            placeholder="Enter in lieu..." />
                    </Form.Group>
                    <p className="form-label">Days To Use:</p>
                    <Form.Group className='mb-4'>
                        <Form.Control
                            value={daysToUse.toString()}
                            disabled
                            type='Number'
                            placeholder="Enter days to use..." />
                    </Form.Group>
                    <p className="form-label">Who needs to view (Click to delete):</p>
                    <Form.Group className='mb-4'>
                        <ul className='list-style'>
                            {approver !== "" &&
                                <li className='mb-2'>{approver}</li>
                            }
                            {access.map((emp) => {
                                return (
                                    <li onClick={() => {
                                        setConfirm(true)
                                        setDeleteItem(emp)
                                    }} className='mb-2 cursor'>{emp}</li>
                                )
                            })}
                        </ul>
                        <Form.Control
                            value={tempAdd}
                            onChange={(e) => setTempAdd(e.currentTarget.value)}
                            placeholder="Enter email of who needs to view..."
                            className='mb-2'
                        />
                        <div className='d-flex justify-content-end w-100'>
                            <Button onClick={() => {
                                const temp = access
                                temp.push(tempAdd)
                                setAccess(temp)
                                setTempAdd("")
                            }} className="buttonHS w-fc">Add</Button>
                        </div>

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => createUser()} className="buttonHS w-fc">Save User</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={confirm} onHide={() => {
                setConfirm(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-bold">Are you sure you want to remove this user?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => {
                            const temp = access.filter((a) => a !== deleteItem)
                            setAccess(temp)
                        }} className="buttonHS w-fc">Yes</Button>
                        <Button onClick={() => setConfirm(false)} className="buttonHS w-fc ml-2">No</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={(e) => {
                    console.log(e.row)
                    setEditing(true)
                    openModal()
                    setStartDate(new Date(moment(e.row.startDate, "DD/MM/yyyy").toString()))
                    setFullName(e.row.fullName)
                    setEmail(e.row.email)
                    setApprover(e.row.approver)
                    setEntitlement(e.row.entitlement)
                    setDaysForService(e.row.daysForService)
                    setDepartment(e.row.department)
                    setXmas(e.row.xmas)
                    setDaysToUse(e.row.daysToUse)
                    setAccess(e.row.access)
                    setInLieu(e.row.inLieu)
                    setCarryOver(e.row.carryOver)
                }}
            />
        </Row>
    )
}

export default SetEmp