import { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
interface Props {
    active: string;
    onClick: (selected: string) => void
    hr?: boolean
    man?: boolean
}

const SubLinks = ({ active, onClick, hr = false, man = false }: Props) => {
    const [links, setLinks] = useState<string[]>([])
    const [activeSub, setActiveSub] = useState<string>("")
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const [view, setView] = useState<boolean>(false)

    useEffect(() => {
        if (active === "Dashboard") {
            const arr = ["All"]
            if (hr) {
                arr.push("HR")
            }
            if (man) {
                arr.push("Manager")
            }
            setLinks(arr)
            setActiveSub("All")
        }
        if (active === "My Holidays") {
            setLinks(["All", "Pending Approval", "Approved", "Rejected"])
            setActiveSub("All")
        }
        if (active === "HR Portal") {
            setLinks(["Holidays", "Sickness", "Graph"])
            setActiveSub("Holidays")
        }
        if (active === "Manager Portal") {
            setLinks(["Table", "Cancel Requests"])
            setActiveSub("Table")
        }
        if (active === "Sickness") {
            setLinks(["All"])
            setActiveSub("All")
        }
        if (active === "Settings") {
            setLinks(["Employees"])
            setActiveSub("Employees")
        }
    }, [active])
    return (
        <div className="subLinks">
            {mobileMedia ?
            <>
            {view ?
                <>
                {links.map((link) => {
                    return (
                        <a onClick={() => {
                            setActiveSub(link)
                            onClick(link)
                            setView(false)
                        }} className={activeSub === link ? "subLinks__link subLinks__link--active" : "subLinks__link"}>{link}</a>
                    )
                })}
            </>
            :
            <FaBars onClick={() => setView(true)} size={35} />
        }
            </>
                :
                <>
                    {links.map((link) => {
                        return (
                            <a onClick={() => {
                                setActiveSub(link)
                                onClick(link)
                            }} className={activeSub === link ? "subLinks__link subLinks__link--active" : "subLinks__link"}>{link}</a>
                        )
                    })}
                </>
            }
        </div>
    )
}

export default SubLinks