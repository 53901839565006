import {AiFillCloseCircle, AiFillCheckCircle} from "react-icons/ai"
import {BsFillClockFill} from "react-icons/bs"
interface Props {
    text: string
}

const Status = ({ text }: Props) => {
    const red = ["Absent", "Rejected", "Unpaid Sickness"]
    const orange = ["Awaiting Self-Cert", "Pending Approval", "Cancel requested"]
    const green = ["Paid Sickness", "Approved", "Returned", "Completed"]
    return (
        <div className={`status`}>
            {red.includes(text) &&
            <AiFillCloseCircle color="#e65454" size={20}/>
            }
            {orange.includes(text) &&
            <BsFillClockFill color="#f89748" size={18}/>
            }
            {green.includes(text) &&
            <AiFillCheckCircle color="#75ca6a" size={20}/>
            }
            <p className={`status__text ${red.includes(text) && "status--red"} ${orange.includes(text) && "status--orange"} ${green.includes(text) && "status--green"}`}>{text}</p>
        </div>
    )
}

export default Status