import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from "react"
import { Calendar as BigCal, momentLocalizer } from 'react-big-calendar'
import Status from '../../Components/Status';
import { CalEvent, Event } from '../Home';
import moment from 'moment';
interface Props {
   events: Event[]
}

const ManCal = ({ events }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [tempEvent, setTempEvent] = useState<CalEvent>({
        title: "",
        end: new Date(),
        start: new Date(),
        allDay: true
    })
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [days, setDays] = useState<string>("")
    const formattedEvents = () => {
        const temp: CalEvent[] = events.map((item) => {
            return {
                title: `${item.employee.name} - ${item.type} - ${item.reason}`,
                end: new Date(item.end),
                start: new Date(item.start),
                allDay: true
            }
        })
        return temp
        
    }
    const handleSelectSlot = useCallback(
        //@ts-ignore
        ({ start, end }) => {
            const sDate = new Date(start).toString()
            const eDate = new Date(end).toString()
            setStartDate(sDate)
            setEndDate(eDate)
            setDays(moment(eDate).diff(sDate, 'days').toString())
        },
        []
    )
    const localizer = momentLocalizer(moment)
    const rows: GridRowsProp = [
        {
            id: 1,
            name: 'Lewis Toseland',
            reason: 'Holiday on a cruise ship blah blah blah blah blah...',
            leave: "Annual Leave",
            start: "24/08/2024",
            end: "31/08/2024",
            managerApproval: <Status text='Pending Approval' />
        },
        {
            id: 2,
            name: 'Lewis Toseland',
            reason: 'Holiday on a cruise ship blah blah blah blah blah...',
            leave: "Annual Leave",
            start: "24/08/2024",
            end: "31/08/2024",
            managerApproval: <Status text='Pending Approval' />
        },
        {
            id: 3,
            name: 'Lewis Toseland',
            reason: 'Holiday on a cruise ship blah blah blah blah blah...',
            leave: "Annual Leave",
            start: "24/08/2024",
            end: "31/08/2024",
            managerApproval: <Status text='Pending Approval' />
        },
    ];

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Full Name', width: 200 },
        { field: 'reason', headerName: 'Reason for Absence', width: 700 },
        { field: 'leave', headerName: 'Leave Type', width: 200 },
        { field: 'start', headerName: 'Start Date', width: 200 },
        { field: 'end', headerName: 'End Date', width: 200 },
        {
            field: 'managerApproval', headerName: 'Manager Approval Status', width: 200,
            renderCell: (params: any) => (
                <strong>
                    {params.value}
                </strong>
            ),
        },
    ];

    return (
        <div >
            <BigCal
                localizer={localizer}
                events={[...formattedEvents(), tempEvent]}
                toolbar={true}
                onSelectSlot={handleSelectSlot}
            />
        </div>
    )
}

export default ManCal