import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Calendar as BigCal, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { CalEvent, Event, User, graphAPIData } from "../Home";
import DateItem from "../../Components/DateItem";
import { v4 as uuidv4 } from 'uuid';
import { Employee } from "../../App";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import emps from "../../Data/Emps.json"
interface Props {
    accessHr: boolean
    emps: Employee[]
    events: Event[]
    allEvents: Event[]
    user?: graphAPIData
    userDetails: Employee
    addEvent: (event: Event) => void
    delHol: (event: Event) => void
    requestDel: (event: Event) => void
    viewing: boolean
    handleClose: () => void
    filter: string
    bankHolidays: CalEvent[]
    yearSelection: string
}
const MyHolidays = ({ bankHolidays, emps, events, user, requestDel, addEvent, userDetails, viewing, handleClose, filter = "All", accessHr, delHol, yearSelection }: Props) => {
    const [endDate, setEndDate] = useState<Date | null>()
    const [startDate, setStartDate] = useState<Date | null>()
    const [days, setDays] = useState<string>("")
    const [type, setType] = useState<string>("")
    const [emp, setEmp] = useState<User>({
        email: "",
        id: "",
        name: ""
    })
    const [calEvents, setCalEvents] = useState<CalEvent[]>([])
    const [reason, setReason] = useState<string>("")
    const [required, setRequired] = useState<boolean>(false)
    const localizer = momentLocalizer(moment)
    moment.locale("en-GB");
    const tabletMedia = window.matchMedia("(max-width: 1250px)").matches
    const createEvent = () => {
        if (startDate === undefined || endDate === undefined || days === "" || days === "0" || type === "") {
            setRequired(true)
        } else {
            const temp: Event = {
                comments: "",
                reason: reason,
                allDay: true,
                managerStatus: "Pending Approval",
                hrStatus: "Pending Approval",
                daysTaken: parseFloat(days),
                start: new Date(startDate || "").toString(),
                end: new Date(endDate || "").toString(),
                type: type,
                id: uuidv4(),
                employee: {
                    email: user?.mail || "",
                    id: user?.id || "",
                    name: user?.displayName || ""
                },
                access: userDetails.access,
                manager: userDetails.approver
            }
            if (accessHr) {
                temp.employee = emp
                const accessTemp = emps.filter((a) => a.email.toLowerCase() === emp.email.toLowerCase())
                temp.access = accessTemp[0].access
                temp.manager = accessTemp[0].approver
            }

            addEvent(temp)
            handleClose()
        }
    }

    const formatCalEvents = () => {
        const temp: CalEvent[] = bankHolidays.filter((a) => a.title.includes("Bank Holiday") || a.title.includes("Christmas Shutdown Period"))
        events.map((item) => {
            if (item.employee.email.toLowerCase() === userDetails.email.toLowerCase() && item.hrStatus !== "Rejected" && item.managerStatus !== "Rejected") {
                if (filter === "All") {
                    if (item.daysTaken <= 1) {
                        temp.push({
                            title: `${item.type} - ${item.managerStatus} - ${item.reason}`,
                            end: new Date(item.start),
                            start: new Date(item.start),
                            allDay: true
                        })
                    } else {
                        temp.push({
                            title: `${item.type} - ${item.managerStatus} - ${item.reason}`,
                            end: new Date(item.end),
                            start: new Date(item.start),
                            allDay: true
                        })
                    }

                }
                if (item.managerStatus === filter) {
                    if (item.daysTaken <= 1) {
                        temp.push({
                            title: `${item.type} - ${item.managerStatus} - ${item.reason}`,
                            end: new Date(item.start),
                            start: new Date(item.start),
                            allDay: true
                        })
                    } else {
                        temp.push({
                            title: `${item.type} - ${item.managerStatus} - ${item.reason}`,
                            end: new Date(item.end),
                            start: new Date(item.start),
                            allDay: true
                        })
                    }
                }
            }
        })
        setCalEvents(temp)
    }

    useEffect(() => {
        formatCalEvents()
    }, [])

    useEffect(() => {
        formatCalEvents()
    }, [filter])

    const determineDelType = (event: Event) => {
        if(event.managerStatus === "Approved") {
            requestDel(event)
        } else {
            delHol(event)
        }
    }
    return (
        <>
            <Modal show={viewing} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-bold">Request a holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {accessHr &&
                        <Row className="mb-3">
                            <Col>
                                <p className="form-label">Select employee: *</p>
                                <Form.Group controlId="raiseTicket.summary">
                                    <Typeahead
                                        placeholder='Search for user...'
                                        labelKey="fullName"
                                        onChange={(selected) => {
                                            //@ts-ignore
                                            setEmp({ email: selected[0].email, name: selected[0].fullName, id: "" })
                                        }}
                                        options={emps}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    <Row className="mb-3 mt-2">
                        <Col>
                            <p className="form-label">Start Date: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <DatePicker

                                    className="form-control"
                                    selected={startDate}
                                    minDate={new Date(parseInt(yearSelection), 0, 1)}
                                    maxDate={endDate && endDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="Select a date..."
                                    onChange={(date) => setStartDate(date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="form-label">Leave Type: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Select onChange={(e) => setType(e.currentTarget.value)}>
                                    <option>Select leave type...</option>
                                    <option value="Annual Leave">Annual Leave</option>
                                    <option value="Unpaid Leave">Unpaid Leave</option>
                                    <option value="In-Lieu">In-Lieu</option>
                                    <option value="Medical">Medical</option>
                                    <option value="Paternity/Maternity">Paternity/Maternity</option>
                                    <option value="Volunteer Day">Volunteer Day</option>
                                    <option value="Compassionate">Death of a near relative</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">Return To Work Date: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <DatePicker
                                    className="form-control"
                                    dateFormat={"dd/MM/yyyy"}
                                    selected={endDate}
                                    minDate={startDate ? startDate : new Date(parseInt(yearSelection), 0, 1)}
                                    placeholderText="Select a date..."
                                    onChange={(date) => setEndDate(date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="form-label">Amount of Days: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Control
                                    value={days}
                                    type={"Number"}
                                    step={"0.5"}
                                    min={0}
                                    onChange={(value) => setDays(value.currentTarget.value)}
                                    placeholder="Enter amount of days..." />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">{type === "Compassionate" ? "Relation to Deceased" : "Reason for Absence"}:</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Control
                                    value={reason}
                                    onChange={(value) => setReason(value.currentTarget.value)}
                                    placeholder={type === "Compassionate" ? "Enter relation to deceased..." : "Enter reason for absence..."} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {required &&
                        <Row className="mb-3">
                            <Col>
                                <div className="required">
                                    <p>* Please fill in all required fields</p>
                                </div>
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => createEvent()} className="buttonHS w-fc">Submit Holiday</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Row className="h-100">
                <Col sm={6} className={`d-flex flex-column ${tabletMedia && "w-100"}`}>
                    {events.sort((a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()).map((item) => {
                        if (filter === "All") {
                            return <DateItem deleteHoliday={(event) => determineDelType(event)} deletable statusFormat onClick={(event) => { }} event={item} />
                        }
                        if (item.managerStatus === filter) {
                            return <DateItem deleteHoliday={(event) => determineDelType(event)} deletable statusFormat onClick={(event) => { }} event={item} />

                        }
                    })}
                    <div className="d-flex justify-content-end">
                    </div>
                </Col>
                {!tabletMedia &&
                    <Col sm={6}>
                        <div className={"h-100"}>
                            <BigCal
                                localizer={localizer}
                                events={calEvents}
                                toolbar={true}
                                startAccessor={"start"}
                                endAccessor={"end"}
                            />

                        </div>
                    </Col>
                }
            </Row>
        </>
    )
}

export default MyHolidays