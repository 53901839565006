import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from "react"
import Status from '../../Components/Status';
import { Event } from '../Home';
import moment from 'moment';
import { Employee } from '../../App';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
interface Props {
    setSelected: (selected: boolean) => void
    selectionModel: GridRowSelectionModel
    setSelectionModel: (model: GridRowSelectionModel) => void
    events: Event[]
    editValue: Event
    viewing: boolean
    handleClose: () => void
    employees: Employee[]
    editEvent: (event: Event) => void
}

const HrHolidays = ({ setSelected, events, editValue, editEvent, selectionModel = [], setSelectionModel, employees, viewing, handleClose }: Props) => {

    const [endDate, setEndDate] = useState<Date | null>()
    const [startDate, setStartDate] = useState<Date | null>()
    const [days, setDays] = useState<string>("")
    const [type, setType] = useState<string>("")
    const [reason, setReason] = useState<string>("")

    const getEventChanges = () => {
        const temp = editValue
        temp.daysTaken = parseFloat(days)
        temp.type = type
        temp.reason = reason
        temp.start = startDate?.toString() || new Date().toString()
        temp.end = endDate?.toString() || new Date().toString()
        editEvent(temp)
    }

    const getDaysUsed = (emp: Employee) => {
        let total = 0
        events.map((event) => {
            if (event.hrStatus === "Approved" && event.managerStatus === "Approved" && event.employee.email.toLowerCase() === emp.email.toLowerCase() && (event.type === "Annual Leave" || event.type === "Medical" || event.type === "In-Lieu")) {
                total = total + event.daysTaken
            }
        })
        total = total + emp.xmas
        return total
    }

    const rows: GridRowsProp = events.map((event, index) => {
        const emp = employees.filter((item) => item.email.toLowerCase() === event.employee.email.toLowerCase())
        return {
            id: event.id,
            name: event.employee.name,
            reason: event.reason,
            leave: event.type,
            start: moment(new Date(event.start)).toISOString(),
            end: moment(new Date(event.end)).toISOString(),
            days: `${event.daysTaken} Days`,
            daysRem: `${(emp[0].entitlement + emp[0].daysForService + emp[0].carryOver + emp[0].inLieu) - getDaysUsed(emp[0])} Days`,
            hrApproval: <Status text={event.hrStatus} />,
            managerApproval: <Status text={event.managerStatus} />
        }
    })

    const columns: GridColDef[] = [
        {
            field: 'hrApproval', headerName: 'HR Approval Status', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        {
            field: 'managerApproval', headerName: 'Manager Approval Status', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        { field: 'name', headerName: 'Full Name', width: 200 },
        { field: 'leave', headerName: 'Leave Type', width: 200 },
        { field: 'reason', headerName: 'Reason for Absence', width: 400 },
        { field: 'days', headerName: 'Days Taken', width: 200 },
        { field: 'start', headerName: 'Start Date', width: 200,
        renderCell: (params) => {
            return moment(new Date(params.row.start)).format("DD/MM/yyyy");
        }
    },
        { field: 'end', headerName: 'Return Date', width: 200,
        renderCell: (params) => {
            return moment(new Date(params.row.end)).format("DD/MM/yyyy");
        } },
        { field: 'daysRem', headerName: 'Days Remaining', width: 200 },
    ];

    useEffect(() => {
        console.log(selectionModel)
        if (selectionModel.length > 0) {
            setSelected(true)
        }
        if (selectionModel.length === 0) {
            setSelected(false)
        }
    }, [selectionModel])

    useEffect(() => {
        if(editValue.id !== "") {
            setDays(editValue.daysTaken.toString())
            setEndDate(new Date(editValue.end))
            setStartDate(new Date(editValue.start))
            setType(editValue.type)
            setReason(editValue.reason)
        }
    }, [editValue])

    return (
        <div className='pb-7'>
            <Modal show={viewing} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-bold">Edit holiday request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">Start Date: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <ReactDatePicker

                                    className="form-control"
                                    selected={startDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="Select a date..."
                                    onChange={(date) => setStartDate(date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="form-label">Leave Type: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Select value={type} onChange={(e) => setType(e.currentTarget.value)}>
                                    <option>Select leave type...</option>
                                    <option value="Annual Leave">Annual Leave</option>
                                    <option value="Unpaid Leave">Unpaid Leave</option>
                                    <option value="In-Lieu">In-Lieu</option>
                                    <option value="Medical">Medical</option>
                                    <option value="Volunteer Day">Volunteer Day</option>
                                    <option value="Compassionate">Death of a near relative</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">Return To Work Date: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <ReactDatePicker
                                    className="form-control"
                                    dateFormat={"dd/MM/yyyy"}
                                    selected={endDate}
                                    placeholderText="Select a date..."
                                    onChange={(date) => setEndDate(date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="form-label">Amount of Days: *</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Control
                                    value={days}
                                    type={"Number"}
                                    min={0}
                                    step={"0.5"}
                                    onChange={(value) => setDays(value.currentTarget.value)}
                                    placeholder="Enter amount of days..." />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="form-label">{type === "Compassionate" ? "Relation to Deceased" : "Reason for Absence"}:</p>
                            <Form.Group controlId="raiseTicket.summary">
                                <Form.Control
                                    value={reason}
                                    onChange={(value) => setReason(value.currentTarget.value)}
                                    placeholder={type === "Compassionate" ? "Enter relation to deceased..." : "Enter reason for absence..."} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => {
                            getEventChanges()
                            handleClose()
                            }} className="buttonHS w-fc">Save Changes</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <DataGrid
                checkboxSelection
                rows={rows}
                columns={columns}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                rowSelectionModel={selectionModel}
            />
        </div>
    )
}

export default HrHolidays