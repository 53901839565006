import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid"
import { Sickness } from "../HrPortal/HrSickness"
import moment from "moment"
import Status from "../../Components/Status"
import { useEffect, useState } from "react"

interface Props {
    filteredSickness: Sickness[]
    updateMySicknessSelection: (selectionModel: GridRowSelectionModel) => void
}

const SicknessPage = ({filteredSickness, updateMySicknessSelection}: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const rows: GridRowsProp = filteredSickness.map((sick) => {
        return (
            {
                id: sick.id,
                name: sick.user.name,
                reason: sick.reason,
                start: moment(new Date(sick.start)).format("DD/MM/yyyy"),
                end: sick.end === "" ? "" : moment(new Date(sick.end)).format("DD/MM/yyyy"),
                status: <Status text={sick.status} />,
                selfCert: <Status text={sick.selfCert} />,
            }
        )
    })

    useEffect(() => {
        updateMySicknessSelection(selectionModel)
    }, [selectionModel])

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Full Name', width: 200 },
        { field: 'reason', headerName: 'Reason for Absence', width: 400 },
        { field: 'start', headerName: 'Start Date', width: 200 },
        { field: 'end', headerName: 'Return Date', width: 200 },
        {
            field: 'status', headerName: 'Status', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        {
            field: 'selfCert', headerName: 'Self-Cert Completed', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
    ];
    return (
        <div>
            <DataGrid
                checkboxSelection
                rows={rows}
                columns={columns}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
            />
        </div>
    )
}

export default SicknessPage