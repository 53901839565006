import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../authConfig";
import windows from "../Images/windows.png"
import logo from "../Images/logo.png"
import bg from "../Images/bg.svg"
import { Button } from "react-bootstrap";

interface Props {
    importData: () => void
}
const UnauthenticatedHome = ({importData}: Props) => {
    const {instance} = useMsal()
    const handleLogin = (loginType: any) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <div className="unAuth">
            <div className="unAuth-login">
                <div className="unAuth-login-block">
                <img className="unAuth-login-block__logo" src={logo} />
                <p className="unAuth-login-block__header">Sign in to continue to the Holidays & Sickness portal</p>
                <Button className="buttonHS" onClick={() => handleLogin("redirect")}>Continue to portal</Button>
                {/* <Button className="buttonHS" onClick={() => importData()}>Update</Button> */}
                </div>
            </div>
            <div className="unAuth-blue">
                <img className="unAuth-blue__img" src={bg}/>
            </div>
        </div>
    )
}

export default UnauthenticatedHome