import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from "react"
import Status from '../../Components/Status';
import { Event } from '../Home';
import moment from 'moment';
import { Employee } from '../../App';
interface Props {
    setSelected: (selected: boolean) => void
    events: Event[]
    emps: Employee[]
    selectionModel: GridRowSelectionModel
    setSelectionModel: (model: GridRowSelectionModel) => void
    allEvents: Event[]
}

const ManTable = ({ setSelected, events, selectionModel, setSelectionModel, emps, allEvents }: Props) => {
    const rows: GridRowsProp = events.map((event, index) => {
        const entitlement = emps.filter((emp) => emp.email.toLowerCase() === event.employee.email.toLowerCase())
        const getDaysUsed = (emp: Employee) => {
            let total = 0
            allEvents.map((event) => {
                if (event.hrStatus === "Approved" && event.managerStatus === "Approved" && event.employee.email.toLowerCase() === emp.email.toLowerCase() && (event.type === "Annual Leave" || event.type === "Medical" || event.type === "In-Lieu")) {
                    total = total + event.daysTaken
                }
            })
            total = total + emp.xmas
            return total
        }
        return {
            id: event.id,
            name: event.employee.name,
            reason: event.reason,
            leave: event.type,
            daysRemaining: `${(entitlement[0].entitlement + entitlement[0].daysForService + entitlement[0].carryOver + entitlement[0].inLieu) - getDaysUsed(entitlement[0])} Days`,
            start: moment(new Date(event.start)).toISOString(),
            end: moment(new Date(event.end)).toISOString(),
            days: `${event.daysTaken} Days`,
            hrApproval: <Status text={event.hrStatus} />,
            managerApproval: <Status text={event.managerStatus} />
        }
    })

    const columns: GridColDef[] = [
        {
            field: 'managerApproval', headerName: 'Manager Approval Status', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
        renderCell: (params: any) => (
            <strong>
                <Status text={params.value}/>
            </strong>
        ), 
        valueGetter: (params) => params.value.props.text
        },
        { field: 'name', headerName: 'Full Name', width: 200 },
        { field: 'leave', headerName: 'Leave Type', width: 200 },
        { field: 'reason', headerName: 'Reason for Absence', width: 400 },
        { field: 'days', headerName: 'Days Taken', width: 200 },
        { field: 'start', headerName: 'Start Date', width: 200,
        renderCell: (params) => {
            return moment(new Date(params.row.start)).format("DD/MM/yyyy");
        } },
        { field: 'end', headerName: 'Return Date', width: 200,
        renderCell: (params) => {
            return moment(new Date(params.row.end)).format("DD/MM/yyyy");
        } },
        { field: 'daysRemaining', headerName: 'Days Remaining', width: 200 },
    ];

    useEffect(() => {
        if (selectionModel.length > 0) {
            setSelected(true)
        }
        if (selectionModel.length === 0) {
            setSelected(false)
        }
    }, [selectionModel])

    return (
        <div className='pb-7'>
            {events.length > 0 ?
                <DataGrid
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                />
                :
                <div className='d-flex justify-content-center align-items-center'>
                    <h2>No manager tasks available</h2>
                </div>
            }

        </div>
    )
}

export default ManTable