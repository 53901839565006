import React, { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Sickness } from "../HrPortal/HrSickness";
import moment from "moment";
import { SelfCertForm } from "./SelfCert";
import { CalEvent } from "../Home";
import { FaArrowLeft } from "react-icons/fa6";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
interface Props {
    sickness: Sickness;
    selfCert: { selfCert: SelfCertForm };
    bankHolidays: CalEvent[]
    back: () => void
}


const SelfCertView = ({ sickness, selfCert, bankHolidays, back }: Props) => {
    const [image, setImage] = useState<string>("")
    const calcWeekDays = (firstDate: Date, secondDate: Date) => {
        var lastDay = moment(secondDate);
        var firstDay = moment(firstDate);
        let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 -
            (firstDay.day() - lastDay.day()) * 2) / 7;

        if (lastDay.day() == 6) calcBusinessDays--;//SAT
        if (firstDay.day() == 0) calcBusinessDays--;//SUN

        return calcBusinessDays;
    }

    const calcBankHolidays = (firstDate: Date, secondDate: Date) => {
        let total = 0
        bankHolidays.map((bank) => {
            const hol = moment(new Date(bank.start))

            if (hol.isBetween(moment(firstDate), moment(secondDate))) {
                total = total + 1
            }
        })
        return total
    }

    useEffect(() => {
        console.log(selfCert)
        if (selfCert.selfCert.fileId !== "") {
            const storage = getStorage();
            getDownloadURL(ref(storage, `DoctorsNotes/${selfCert.selfCert.fileId}`))
                .then((url) => {
                    setImage(url)
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [])
    return (
        <div className="d-flex justify-content-center align-items-center overflow-auto pb-5 flex-column">
            <div className="d-flex justify-content-start w-100">
                <a onClick={back} className="backLink"><FaArrowLeft size={24}/> Back to sickness</a>
            </div>
            <div className="d-flex flex-column w-50">
                {selfCert !== undefined ? <>
                    <h2 className="page-nav__title mb-3">Sickness Self-Certification</h2>
                    <div className="mb-3">
                        <p className="form-label">Full name:</p>
                        <p>{sickness.user.name}</p>
                    </div>
                    <div className="d-flex w-100 flex-column">
                        <div className="mb-3">
                            <p className="form-label">Start Date:</p>
                            <p>{moment(sickness.start).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="mb-3">
                            <p className="form-label">Return Date:</p>
                            <p>{moment(sickness.end).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="mb-3">
                            <p className="form-label">Days off:</p>
                            <p>{moment(sickness.end).diff(moment(sickness.start), 'days')} days</p>
                        </div>
                        <div className="mb-3">
                            <p className="form-label">Working days off:</p>
                            <p>{calcWeekDays(new Date(sickness.start), new Date(sickness.end)) - calcBankHolidays(new Date(sickness.start), new Date(sickness.end))} days</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className="form-label">Reason for sickness:</p>
                        {selfCert.selfCert.reason}
                    </div>
                    <div className="mb-3">
                        <p className="form-label">Doctor consulted:</p>
                        {selfCert.selfCert.doctorConsulted}
                    </div>
                    <div className="mb-3">
                        <p className="form-label">Medication taken:</p>
                        {selfCert.selfCert.takingMedication}
                    </div>
                    {selfCert.selfCert.takingMedication !== "No" &&
                        <div className="mb-3">
                            <p className="form-label">Medication details:</p>
                            {selfCert.selfCert.medicationDetails}
                        </div>
                    }
                    {image !== "" &&
                        <div className="mb-3">
                            <p className="form-label">Doctors note:</p>
                            <a target="_blank" href={image}>Click here for doctors note</a>
                        </div>
                    }
                </>
                    :
                    <p>No self cert available</p>
                }
            </div>
        </div>
    );
};

export default SelfCertView;
