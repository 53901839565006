import { useEffect, useState, useReducer } from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import logo from "../Images/smallLogo.png"
import { BiSolidUserCircle, BiCog, BiSolidDashboard, BiCalendar, BiSolidUserAccount, BiSolidUserBadge, BiSolidFirstAid, BiSolidHome, BiSolidDoorOpen } from "react-icons/bi"
import { CalEvent, Event, User, graphAPIData } from "../Pages/Home";
import SubLinks from "./SubLinks";
import DashAll from "../Pages/Dashboard/DashAll";
import DashHr from "../Pages/Dashboard/DashHr";
import DashManager from "../Pages/Dashboard/DashManager";
import MyHolidays from "../Pages/MyHolidays/MyHolidays";
import { Employee } from "../App";
import HrHolidays from "../Pages/HrPortal/HrHolidays";
import { Button, Form, Modal } from "react-bootstrap";
import HrSickness, { Sickness } from "../Pages/HrPortal/HrSickness";
import ManTable from "../Pages/Manager/ManTable";
import ManCal from "../Pages/Manager/ManCal";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import SetEmp from "../Pages/Settings/SetEmp";
import HrGraph from "../Pages/HrPortal/HrGraph";
import SicknessPage from "../Pages/Sickness/SicknessPage";
import SelfCert, { SelfCertForm } from "../Pages/Sickness/SelfCert";
import SelfCertView from "../Pages/Sickness/SelfCertView";
import moment from "moment";
import * as XLSX from 'xlsx';
import ManCancelRequests from "../Pages/Manager/ManCancelRequests";
interface Props {
    getUserDetails: (email: string) => void;
    userDetails: Employee;
    addEvent: (event: Event) => void
    events: Event[]
    manEvents: Event[]
    yearSelection: string;
    backToSelect: () => void;
    manCancelEvents: Event[]
    allEvents: Event[]
    allSickness: Sickness[]
    hrUpdateStatus: (status: string, ids: GridRowSelectionModel, reason?: string) => void
    manUpdateStatus: (status: string, ids: GridRowSelectionModel, reason?: string) => void
    requestDel: (event: Event) => void
    createSickness: (sickness: Sickness) => void
    updateSickness: (sicknessIds: GridRowSelectionModel, type: string) => void
    employees: Employee[]
    checkThreeSick: (user: User) => void
    threeSick: boolean
    delHol: (event: Event) => void
    giveUserAccess: (user: string, accessUser: string) => void
    delHolsId: (ids: string[]) => void
    importData: () => void
    bankHolidays: CalEvent[]
    createNewUser: (emp: Employee) => void
    deleteSickness: (id: string) => void
    allManagers: string[]
    allSelfCerts: { selfCert: SelfCertForm }[]
    submitSelfCert: (selfCert: SelfCertForm, sickness: Sickness) => void
}

const Page = ({ bankHolidays, giveUserAccess, submitSelfCert, requestDel, deleteSickness, yearSelection, backToSelect, allManagers, manCancelEvents, allSelfCerts, createNewUser, delHolsId, delHol, importData, getUserDetails, updateSickness, userDetails, addEvent, checkThreeSick, threeSick = false, events, allEvents, allSickness, manEvents, manUpdateStatus, hrUpdateStatus, employees, createSickness }: Props) => {
    const [activePage, setActivePage] = useState<string>("Dashboard")
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [manSelectionModel, setManSelectionModel] = useState<GridRowSelectionModel>([]);
    const [manCancelSelectionModel, setManCancelSelectionModel] = useState<GridRowSelectionModel>([]);
    const [sicknessSelectionModel, setSicknessSelectionModel] = useState<GridRowSelectionModel>([]);
    const [mySicknessSelectionModel, setMySicknessSelectionModel] = useState<GridRowSelectionModel>([]);
    const [activeSubPage, setActiveSubPage] = useState<string>("All")
    const [rejectReason, setRejectReason] = useState<string>("")
    const [rejectType, setRejectType] = useState<string>("")
    const [showReason, setShowReason] = useState(false);
    const handleClose = () => setShowReason(false);
    const handleShow = () => setShowReason(true);
    const [hrHolidays, setHrHolidays] = useState<boolean>(false)
    const [hrSickness, setHrSickness] = useState<boolean>(false)
    const [viewing, setViewing] = useState<boolean>(false)
    const [viewingEdit, setViewingEdit] = useState<boolean>(false)
    const [viewingUser, setViewingUser] = useState<boolean>(false)
    const [viewingSick, setViewingSick] = useState<boolean>(false)
    const [viewingSickEdit, setViewingSickEdit] = useState<boolean>(false)
    const [accessHr, setAccessHr] = useState<boolean>(false)
    const [accessMan, setAccessMan] = useState<boolean>(false)
    const [editValue, setEditValue] = useState<Event>(
        {
            id: "",
            reason: "",
            type: "",
            daysTaken: 0,
            start: "",
            end: "",
            employee: {
                email: "",
                id: "",
                name: "",
            },
            access: [],
            hrStatus: "",
            managerStatus: "",
        }
    )
    const [loading, setLoading] = useState<boolean>(true)
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState<graphAPIData>();
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const getProfile = async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then(async (response: any) => {
            await callMsGraph(response.accessToken).then(response => {
                setGraphData(response)
            });
        });
    }

    useEffect(() => {
        if (graphData?.mail !== "") {
            console.log(graphData)
            getUserDetails(graphData?.mail || "")
        }
    }, [graphData])

    useEffect(() => {
        getProfile()
        setLoading(true)
    }, [])

    //HR PORTAL - HOLIDAYS
    const hrCompileStatus = (status: string) => {
        hrUpdateStatus(status, selectionModel, rejectReason)
        setRejectReason("")
        setSelectionModel([])
    }

    const delHolidayRequests = () => {
        const temp: string[] = []
        selectionModel.map((id) => temp.push(id.toString()))
        delHolsId(temp)
    }

    const manDelHolidayRequests = () => {
        const temp: string[] = []
        manCancelSelectionModel.map((id) => temp.push(id.toString()))
        delHolsId(temp)
    }

    //MANAGER PORTAL - HOLIDAYS
    const manCompileStatus = (status: string) => {
        manUpdateStatus(status, manSelectionModel, rejectReason)
        setManSelectionModel([])
    }

    const getBothHols = () => {
        const both: Event[] = [...events, ...manEvents]
        return []
    }

    const findEditValue = () => {
        const temp: Event[] = []
        allEvents.map((event) => {
            if (event.id === selectionModel[0]) {
                temp.push(event)
            }
        })
        setEditValue(temp[0])
    }

    const exportSicknessToExcel = () => {
        const filteredEvents = allSickness.filter(event => sicknessSelectionModel.includes(event.id));
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        const calcWeekDays = (firstDate: Date, secondDate: Date) => {
            var lastDay = moment(secondDate);
            var firstDay = moment(firstDate);
            let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 -
                (firstDay.day() - lastDay.day()) * 2) / 7;

            if (lastDay.day() == 6) calcBusinessDays--;//SAT
            if (firstDay.day() == 0) calcBusinessDays--;//SUN

            return calcBusinessDays;
        }

        const calcBankHolidays = (firstDate: Date, secondDate: Date) => {
            let total = 0
            bankHolidays.map((bank) => {
                const hol = moment(new Date(bank.start))

                if (hol.isBetween(moment(firstDate), moment(secondDate))) {
                    total = total + 1
                }
            })
            return total
        }


        // Convert JSON data to worksheet
        const ws = XLSX.utils.json_to_sheet(filteredEvents.map(event => ({
            Employee: event.user.name,
            Department: employees.filter((e) => e.email.toLowerCase() === event.user.email.toLowerCase())[0].department,
            ReportedBy: event.reportedBy.name,
            StartDate: moment(event.start).format("DD/MM/YYYY"),
            ReturnDate: event.end === "Invalid date" ? "" : moment(new Date(event.end)).format("DD/MM/yyyy"),
            DaysOff: calcWeekDays(new Date(event.start), new Date(event.end)) - calcBankHolidays(new Date(event.start), new Date(event.end)) - 1 || "Awaiting return",
            Reason: event.reason,
            Type: event.type
        })));

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sickness");

        // Write workbook to buffer
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create a Blob object
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Create download link and click it programmatically
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Sickness.xlsx';
        link.click();

        // Clean up URL object
        URL.revokeObjectURL(url);
    }

    const exportHolidayToExcel = () => {
        const filteredEvents = allEvents.filter(event => selectionModel.includes(event.id));
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert JSON data to worksheet
        const ws = XLSX.utils.json_to_sheet(filteredEvents.map(event => ({
            Employee: event.employee.name,
            Department: employees.filter((e) => e.email.toLowerCase() === event.employee.email.toLowerCase())[0].department,
            StartDate: moment(event.start).format("DD/MM/YYYY"),
            EndDate: moment(event.end).format("DD/MM/YYYY"),
            DaysTaken: event.daysTaken,
            HRStatus: event.hrStatus,
            ManagerStatus: event.managerStatus,
            Type: event.type,
            Reason: event.reason,
        })));

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Holidays");

        // Write workbook to buffer
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create a Blob object
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Create download link and click it programmatically
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Holidays.xlsx';
        link.click();

        // Clean up URL object
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        setAccessHr(false)
        setAccessMan(false)
        if (graphData) {
            const access = ["ltoseland@eurosafeuk.com", "bmallett@eurosafeuk.com", "cmccabe@eurosafeuk.com"]
            if (access.includes(graphData.mail.toLowerCase())) {
                setAccessHr(true)
            }
        }
    }, [graphData])


    const filterUserSickness = () => {
        // Get the current date
        var now = moment();

        // Get the date 28 days ago
        var twentyEightDaysAgo = moment().subtract(90, 'days');
        const filt = allSickness.filter((sick) => (sick.user.email.toLowerCase() === graphData?.mail.toLowerCase() && sick.status === "Returned" && sick.selfCert === "Awaiting Self-Cert" && moment(sick.end).isBetween(twentyEightDaysAgo, now, null, '[]')))
        return filt
    }
    useEffect(() => {
        if (graphData && allManagers.includes(graphData.mail.toLowerCase())) {
            setAccessMan(true)
        }
    }, [allManagers])

    console.log(manSelectionModel)

    return (
        <>
            <div className="page">
                <div className="page-stick">
                    <div className="page-nav">
                        <div>
                            <div className="d-flex flex-row align-items-center">
                                <img src={logo} className="page-nav__logo" />
                                <div className="d-flex flex-column">
                                    <p className="page-nav__subtitle">Holidays & Sickness Portal {yearSelection}</p>
                                    <h1 className="page-nav__title">{activePage.toLocaleUpperCase()}</h1>
                                </div>
                            </div>

                        </div>

                        {!mobileMedia &&
                            <div className="d-flex flex-row align-items-center">
                                <BiSolidUserCircle size={26} className="page-nav__icon" color="#8F8F8F" />
                                <p className="page-nav__user">{graphData?.displayName}</p>
                            </div>
                        }
                    </div>
                    <div className="page-extra">
                        <Modal show={showReason} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Reason for rejection</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="form-label">Rejection reason:</p>
                                <Form.Group>
                                    <Form.Control
                                        value={rejectReason}
                                        onChange={(value) => setRejectReason(value.currentTarget.value)}
                                        placeholder="Enter rejection reason..." />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="buttonHS" variant="primary" onClick={() => {
                                    if (rejectType === "Man") {
                                        manCompileStatus("Rejected")
                                        setManSelectionModel([])
                                    }
                                    if (rejectType === "HR") {
                                        hrCompileStatus("Rejected")
                                        setSelectionModel([])
                                    }
                                    handleClose()
                                }}>
                                    Reject
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="page-nav-subs">
                            <SubLinks hr={accessHr} man={accessMan} active={activePage} onClick={(link) => setActiveSubPage(link)} />
                        </div>
                        {activePage === "Dashboard" &&
                            <div>
                                <Button
                                    onClick={() => {
                                        setActivePage("My Holidays")
                                        setActiveSubPage("All")
                                        setViewing(true)
                                    }}
                                    className="buttonHS">
                                    Request Holiday
                                </Button>
                            </div>
                        }
                        {activePage === "Sickness" &&
                            <div>
                                {mySicknessSelectionModel.length === 1 &&
                                    <Button
                                        onClick={() => {
                                            setActivePage("Sickness Self Certification")
                                        }}
                                        className="buttonHS">
                                        Complete self-cert
                                    </Button>
                                }

                            </div>
                        }
                        {(activePage === "HR Portal" && activeSubPage === "Sickness") &&
                            <div className="d-flex flex-row">
                                <Button onClick={() => setViewingSick(true)} className="buttonHS buttonHS--right buttonHS--left">Create Sickness</Button>
                                {sicknessSelectionModel.length === 1 &&
                                    <>
                                        <Button onClick={() => {
                                            setViewingSickEdit(true)
                                        }} className="buttonHS buttonHS--right">Edit Sickness</Button>
                                        <Button onClick={() => {
                                            setActivePage("View Sickness Self Certification")
                                        }} className="buttonHS buttonHS--right">View Self-Cert</Button>
                                    </>
                                }
                                {hrSickness &&
                                    <>
                                        <Button onClick={() => updateSickness(sicknessSelectionModel, "Return")} className="buttonHS buttonHS--right">Return Selected</Button>
                                        <Button onClick={() => exportSicknessToExcel()} className="buttonHS">Export Selected</Button>
                                    </>
                                }
                            </div>
                        }
                        {(activePage === "HR Portal" && activeSubPage === "Holidays") &&
                            <div className="d-flex flex-row">
                                {selectionModel.length > 0 &&
                                    <>

                                        <Button
                                            onClick={() => {
                                                hrCompileStatus("Approved")
                                                setSelectionModel([])
                                            }}
                                            className="buttonHS buttonHS--green buttonHS--right buttonHS--left">
                                            HR Approve Selected
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                hrCompileStatus("ApprovedManager")
                                                setSelectionModel([])
                                            }}
                                            className="buttonHS buttonHS--green buttonHS--right buttonHS--left">
                                            HR & Manager Approve Selected
                                        </Button>
                                        {selectionModel.length === 1 ?
                                            <Button
                                                onClick={() => {
                                                    setRejectType("HR")
                                                    handleShow()
                                                }}
                                                className="buttonHS buttonHS--red buttonHS--right">
                                                Reject Selected
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => {
                                                    hrCompileStatus("Rejected")
                                                    setSelectionModel([])
                                                }}
                                                className="buttonHS buttonHS--red buttonHS--right">
                                                Reject Selected
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => {
                                                delHolidayRequests()
                                                setSelectionModel([])
                                            }}
                                            className="buttonHS buttonHS--red buttonHS--right">
                                            Delete Selected
                                        </Button>
                                        {selectionModel.length === 1 &&
                                            <Button onClick={() => {
                                                setViewingEdit(true)
                                                findEditValue()
                                            }} className="buttonHS buttonHS--right">Edit Selected</Button>
                                        }
                                        <Button onClick={() => exportHolidayToExcel()} className="buttonHS">Export Selected</Button>
                                    </>
                                }
                            </div>
                        }
                        {(activePage === "Manager Portal" && activeSubPage === "Table") &&
                            <div className="d-flex flex-row">
                                {manSelectionModel.length > 0 &&
                                    <>
                                        <Button onClick={() => {
                                            manCompileStatus("Approved")
                                            setManSelectionModel([])
                                        }} className="buttonHS buttonHS--green buttonHS--right buttonHS--left">Approve Selected</Button>
                                        {manSelectionModel.length === 1 ?
                                            <Button onClick={() => {
                                                setRejectType("Man")
                                                handleShow()
                                            }} className="buttonHS buttonHS--red buttonHS--right">Reject Selected</Button>
                                            :
                                            <Button onClick={() => {
                                                manCompileStatus("Rejected")
                                                setManSelectionModel([])
                                            }} className="buttonHS buttonHS--red buttonHS--right">Reject Selected</Button>
                                        }
                                    </>
                                }
                            </div>
                        }
                        {(activePage === "Manager Portal" && activeSubPage === "Cancel Requests") &&
                            <div className="d-flex flex-row">
                                {manCancelSelectionModel.length > 0 &&
                                    <>
                                        <Button onClick={() => {
                                            manDelHolidayRequests()
                                            setManCancelSelectionModel([])
                                        }} className="buttonHS buttonHS--red buttonHS--right buttonHS--left">Cancel Selected</Button>
                                    </>
                                }
                            </div>
                        }
                        {activePage === "My Holidays" &&
                            <div>
                                <Button
                                    onClick={() => setViewing(true)}
                                    className="buttonHS">
                                    Request Holiday
                                </Button>
                            </div>
                        }
                        {activePage === "Settings" &&
                            <div>
                                <Button
                                    onClick={() => setViewingUser(true)}
                                    className="buttonHS">
                                    Add New User
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="page-content">
                    {activePage === "Dashboard" &&
                        <>
                            {activeSubPage === "All" &&
                                <DashAll
                                    bankHolidays={bankHolidays}
                                    manEvents={manEvents}
                                    employee={userDetails}
                                    events={events} />}
                            {activeSubPage === "HR" &&
                                <DashHr
                                    allEvents={allEvents}
                                    employees={employees}
                                    bankHolidays={bankHolidays}
                                    updateRequest={(status, ids) => {
                                        hrUpdateStatus(status, ids)
                                    }}
                                    events={
                                        allEvents.filter((event) => event.hrStatus === "Pending Approval")
                                    }
                                />}
                            {activeSubPage === "Manager" && <DashManager
                                manEvents={manEvents}
                                bankHolidays={bankHolidays}
                                updateRequest={(status, ids) => {
                                    manUpdateStatus(status, ids)
                                }}
                                events={manEvents.filter((event) => event.managerStatus === "Pending Approval")} />}
                        </>
                    }
                    {activePage === "My Holidays" && <MyHolidays
                        yearSelection={yearSelection}
                        requestDel={(event) => requestDel(event)}
                        emps={employees}
                        delHol={(event) => delHol(event)}
                        bankHolidays={bankHolidays}
                        filter={activeSubPage}
                        accessHr={accessHr}
                        handleClose={() => setViewing(false)}
                        viewing={viewing}
                        userDetails={userDetails}
                        allEvents={allEvents}
                        addEvent={(event) => addEvent(event)}
                        user={graphData} events={events} />}

                    {activePage === "Sickness" && <SicknessPage
                        updateMySicknessSelection={(sicknessSelectionModel) => setMySicknessSelectionModel(sicknessSelectionModel)}
                        filteredSickness={filterUserSickness()}
                    />}
                    {activePage === "Sickness Self Certification" &&
                        <SelfCert onSubmit={(selfCert, sickness) => {
                            submitSelfCert(selfCert, sickness)
                            setActivePage("Sickness")
                        }} sickness={allSickness.filter((item) => mySicknessSelectionModel[0] === item.id)[0]} />
                    }
                    {activePage === "View Sickness Self Certification" &&
                        <SelfCertView back={() => {
                            setActivePage("HR Portal")
                            setActiveSubPage("Sickness")
                        }} bankHolidays={bankHolidays} selfCert={allSelfCerts.filter((item) => sicknessSelectionModel[0] === item.selfCert.sicknessId)[0]} sickness={allSickness.filter((item) => sicknessSelectionModel[0] === item.id)[0]} />
                    }
                    {activePage === "HR Portal" &&
                        <>
                            {activeSubPage === "Holidays" &&
                                <HrHolidays
                                    editEvent={(event) => addEvent(event)}
                                    employees={employees}
                                    viewing={viewingEdit}
                                    editValue={editValue}
                                    handleClose={() => setViewingEdit(false)}
                                    selectionModel={selectionModel}
                                    setSelectionModel={(model) => setSelectionModel(model)}
                                    events={allEvents}
                                    setSelected={(selected) => setHrHolidays(selected)}
                                />}
                            {activeSubPage === "Sickness"
                                && <HrSickness
                                    deleteSickness={(id: string) => deleteSickness(id)}
                                    handleEditClose={() => setViewingSickEdit(false)}
                                    editSick={viewingSickEdit}
                                    bankHolidays={bankHolidays}
                                    threeSick={threeSick}
                                    checkThreeSick={(user) => checkThreeSick(user)}
                                    updateSicknessSelection={(sicknessSelectionModel) => setSicknessSelectionModel(sicknessSelectionModel)}
                                    allSickness={allSickness}
                                    allEmployees={employees}
                                    createSicknessEvent={(sickness) => createSickness(sickness)}
                                    user={graphData}
                                    handleClose={() => setViewingSick(false)}
                                    viewing={viewingSick}
                                    setSelected={(selected) => setHrSickness(selected)} />}
                            {activeSubPage === "Graph" &&
                                <HrGraph
                                    events={allEvents}
                                    emps={employees}
                                />
                            }
                        </>
                    }
                    {activePage === "Manager Portal" &&
                        <>
                            {activeSubPage === "Table" &&
                                <ManTable
                                    emps={employees}
                                    allEvents={allEvents}
                                    events={manEvents}
                                    setSelected={
                                        (selected) => setHrHolidays(selected)
                                    }
                                    selectionModel={manSelectionModel}
                                    setSelectionModel={(model) => setManSelectionModel(model)}

                                />}
                            {activeSubPage === "Cancel Requests" &&
                                <ManCancelRequests
                                    emps={employees}
                                    allEvents={allEvents}
                                    events={manCancelEvents}
                                    setSelected={
                                        (selected) => setHrHolidays(selected)
                                    }
                                    selectionModel={manCancelSelectionModel}
                                    setSelectionModel={(model) => setManCancelSelectionModel(model)}

                                />}
                            {activeSubPage === "Calendar" && <ManCal events={[]} />}
                        </>
                    }
                    {activePage === "Settings" &&
                        <SetEmp
                            giveUserAccess={(user, access) => giveUserAccess(user, access)}
                            allEvents={allEvents}
                            openModal={() => setViewingUser(true)}
                            createNewUser={(emp) => createNewUser(emp)}
                            emps={employees}
                            viewing={viewingUser}
                            handleClose={() => setViewingUser(false)}
                        />
                    }
                </div>
                <div className="page-links">
                    <a className={`page-links__link`} onClick={() => {
                        setActiveSubPage("All")
                        setActivePage("Dashboard")
                        backToSelect()
                    }}>
                        <BiSolidDoorOpen size={26} className="page-nav__icon" color={"#8F8F8F"} />
                        Year Selection
                    </a>
                    <a className={`page-links__link ${activePage === "Dashboard" && "page-links__link--active"}`} onClick={() => {
                        setActiveSubPage("All")
                        setActivePage("Dashboard")
                    }}>
                        <BiSolidDashboard size={26} className="page-nav__icon" color={activePage === "Dashboard" ? "#130922" : "#8F8F8F"} />
                        Dashboard
                    </a>
                    <a className={`page-links__link ${activePage === "My Holidays" && "page-links__link--active"}`} onClick={() => {
                        setActiveSubPage("All")
                        setActivePage("My Holidays")
                    }}>
                        <BiCalendar size={26} className="page-nav__icon" color={activePage === "My Holidays" ? "#130922" : "#8F8F8F"} />
                        My Holidays
                    </a>
                    <a className={`page-links__link ${["Sickness Self Certification", "Sickness"].includes(activePage) && "page-links__link--active"}`} onClick={() => {
                        setActivePage("Sickness")
                    }}>
                        <BiSolidFirstAid size={26} className="page-nav__icon" color={["Sickness Self Certification", "Sickness"].includes(activePage) ? "#130922" : "#8F8F8F"} />
                        Sickness
                    </a>
                    {accessHr &&
                        <a className={`page-links__link ${activePage === "HR Portal" && "page-links__link--active"}`} onClick={() => {
                            setActiveSubPage("Holidays")
                            setActivePage("HR Portal")
                        }}>
                            <BiSolidUserAccount size={26} className="page-nav__icon" color={activePage === "HR Portal" ? "#130922" : "#8F8F8F"} />
                            HR Portal
                        </a>
                    }
                    {accessMan &&
                        <a className={`page-links__link ${activePage === "Manager Portal" && "page-links__link--active"}`} onClick={() => {
                            setActiveSubPage("Table")
                            setActivePage("Manager Portal")
                        }
                        }>
                            <BiSolidUserBadge size={26} className="page-nav__icon" color={activePage === "Manager Portal" ? "#130922" : "#8F8F8F"} />
                            Manager Portal
                        </a>
                    }
                    {accessHr &&
                        <a className={`page-links__link ${activePage === "Settings" && "page-links__link--active"}`} onClick={() => {
                            setActiveSubPage("Employees")
                            setActivePage("Settings")
                        }}>
                            <BiCog size={26} className="page-nav__icon" color={activePage === "Settings" ? "#130922" : "#8F8F8F"} />
                            Settings
                        </a>
                    }
                </div>
            </div >
        </>
    )
}

export default Page