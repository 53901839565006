import moment from "moment"
import { Event } from "../Pages/Home"
import { BsFillClockFill, BsFillCheckCircleFill } from "react-icons/bs"
import { AiFillCloseCircle } from "react-icons/ai"
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useState } from "react"

interface Props {
    event: Event
    onClick: (event: Event) => void
    deleteHoliday?: (event: Event) => void
    statusFormat?: boolean
    deletable?: boolean
}

const DateItem = ({ event, onClick, statusFormat = false, deletable = false, deleteHoliday }: Props) => {
    const [delView, setDelView] = useState<boolean>(false)
    moment.locale("en-GB");
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const getIcon = () => {
        if (event.managerStatus === "Pending Approval") {
            return <BsFillClockFill size={35} color="#F29D38" />
        }
        if (event.managerStatus === "Approved") {
            return <BsFillCheckCircleFill size={35} color="#75ca6a" />
        }
        if (event.managerStatus === "Rejected") {
            return <AiFillCloseCircle size={36} color="#e65454" />
        }
    }

    return (
        <div onClick={() => deletable ? event.managerStatus!== "Cancel requested" && setDelView(!delView) : onClick(event)} className="dateItem">
            <div className="dateItem-content">
                <div className="dateItem-content-date">
                    <p className="dateItem-content-date__num">{moment(event.start).format("DD")}</p>
                    <p className="dateItem-content-date__mon">{moment(event.start).format("MMM")}</p>
                </div>
                <div className="dateItem-content-info">
                    {statusFormat ?
                        <p className="dateItem-content-info__title">
                            {`${moment(event.start).format("DD/MM/yyyy")} - ${event.managerStatus}`}
                        </p>
                        :
                        <p className="dateItem-content-info__title">
                            {`${event.employee.name} - ${moment(event.start).format("DD/MM/yyyy")}`}
                        </p>
                    }
                    <p className="dateItem-content-info__type">{event.type}</p>
                    <p className="dateItem-content-info__length">{event.daysTaken} Days</p>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
            {!mobileMedia && getIcon()}
            {delView &&
                <div onClick={() => {
                    deleteHoliday && deleteHoliday(event)
                    setDelView(false)
                    }} className="p-3 d-flex justify-content-center align-items-center dateItem-delete ml-2">
                    <RiDeleteBin2Fill size={35} color="white"/>
                </div>
            }
            </div>
        </div>
    )
}

export default DateItem