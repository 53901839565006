import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Sickness } from "../HrPortal/HrSickness";
import moment from "moment";

interface Props {
    sickness: Sickness;
    onSubmit: (formData: SelfCertForm, sickness: Sickness) => void;
}

export interface SelfCertForm {
    returnDate: Date | null;
    reason: string;
    doctorConsulted: string;
    takingMedication: string;
    medicationDetails: string;
    isChecked: boolean;
    selectedFile: File | null;
    fileId: string;
    sicknessId: string; // Add sicknessId to the form data
}

const SelfCert = ({ sickness, onSubmit }: Props) => {
    const [formData, setFormData] = useState<SelfCertForm>({
        returnDate: null,
        reason: "",
        doctorConsulted: "",
        takingMedication: "",
        medicationDetails: "",
        isChecked: false,
        selectedFile: null,
        fileId: "",
        sicknessId: sickness.id // Set the sicknessId initially
    });
    const [errors, setErrors] = useState<string[]>([]);
    const tabletMedia = window.matchMedia("(max-width: 1250px)").matches
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = () => {
        setFormData({ ...formData, isChecked: !formData.isChecked });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFormData({ ...formData, selectedFile: event.target.files[0] });
        }
    };

    const handleReturnDateChange = (date: Date | null) => {
        setFormData({ ...formData, returnDate: date });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationErrors: string[] = [];

        // Validate returnDate if visible
        if (isFormFileVisible() && !formData.returnDate) {
            validationErrors.push("Return to work date is required.");
        }

        // Validate reason
        if (!formData.reason.trim()) {
            validationErrors.push("Please state why you were unfit for work.");
        }

        // Validate doctorConsulted
        if (!formData.doctorConsulted) {
            validationErrors.push("Did you consult a doctor is required.");
        }

        // Validate takingMedication
        if (!formData.takingMedication) {
            validationErrors.push("Have you been taking medication is required.");
        }

        // Validate medicationDetails if takingMedication is "Yes" and visible
        if (isFormFileVisible() && formData.takingMedication === "Yes" && !formData.medicationDetails.trim()) {
            validationErrors.push("Please state what is the medication and how it affects your work.");
        }

        // Validate isChecked
        if (!formData.isChecked) {
            validationErrors.push("You must confirm that the information given is true.");
        }

        // Validate file upload
        if (isFormFileVisible() && !formData.selectedFile) {
            validationErrors.push("Please upload a doctor's note.");
        }

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors([]);
            onSubmit(formData, sickness); // Pass formData up through props
            // Proceed with form submission logic
            console.log("Form submitted with data:", formData);
        }
    };

    const isFormFileVisible = () => {
        if (formData.returnDate) {
            const differenceInDays = moment(formData.returnDate).diff(moment(sickness.start), 'days');
            return differenceInDays >= 7;
        }
        return false;
    };

    return (
        <div className="d-flex justify-content-center align-items-center overflow-auto pb-5">
            <div className={`d-flex flex-column ${tabletMedia ? "w-100 pt-5" : "w-50"}`}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <p className="form-label">Return to work date: *</p>
                        <ReactDatePicker
                            wrapperClassName="w-100"
                            className="form-control"
                            selected={formData.returnDate}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select a date..."
                            onChange={handleReturnDateChange}
                        />
                    </Form.Group>
                    {isFormFileVisible() && (
                        <Form.Group controlId="formFile" className="mb-3">
                            <p className="form-label">Upload a doctor's note: *</p>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                        <p className="form-label">Please state why you were unfit for work: *</p>
                        <Form.Control
                            name="reason"
                            onChange={handleInputChange}
                            value={formData.reason}
                            placeholder="Enter reason..."
                            className="form-control"
                            as="textarea"
                            rows={3}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <p className="form-label">Did you consult a doctor: *</p>
                        <Form.Select name="doctorConsulted" onChange={handleInputChange} value={formData.doctorConsulted}>
                            <option value="">Select an option...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <p className="form-label">Have you been taking medication: *</p>
                        <Form.Select name="takingMedication" onChange={handleInputChange} value={formData.takingMedication}>
                            <option value="">Select an option...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Select>
                    </Form.Group>
                    {formData.takingMedication === "Yes" &&
                        <Form.Group className="mb-3">
                            <p className="form-label">Please state what is the medication and how it affects your work?: *</p>
                            <Form.Control
                                name="medicationDetails"
                                onChange={handleInputChange}
                                value={formData.medicationDetails}
                                placeholder="Enter response..."
                                className="form-control"
                                as="textarea"
                                rows={3}
                            />
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <p className="form-label">I declare that the information given is true, to the best of my knowledge. I understand that false
                            information may result in loss of sick pay/company pay and/or disciplinary action: *</p>
                        <Form.Check
                            type="checkbox"
                            id="singleCheckbox"
                            label="I confirm the above is true"
                            checked={formData.isChecked}
                            onChange={handleCheckboxChange}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-center mb-3">
                        <Button type="submit" className="buttonHS w-fc">Submit Request</Button>
                    </div>
                    {errors.length > 0 && (
                        <Alert className="mb-5" variant="danger">
                            <ul>
                                {errors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default SelfCert;
