import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from "react"
import Status from '../../Components/Status';
import { Col, Modal, Row, Form, Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { CalEvent, User, graphAPIData } from '../Home';
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Employee } from '../../App';
import moment from 'moment';

interface Props {
    setSelected: (selected: boolean) => void
    viewing: boolean
    handleClose: () => void
    handleEditClose: () => void
    user?: graphAPIData
    createSicknessEvent: (sickness: Sickness) => void
    allEmployees: Employee[]
    allSickness: Sickness[]
    updateSicknessSelection: (selectionModel: GridRowSelectionModel) => void
    threeSick: boolean
    checkThreeSick: (user: User) => void
    deleteSickness: (id: string) => void
    bankHolidays: CalEvent[]
    editSick: boolean

}

export interface Sickness {
    user: User
    id: string
    reason: string
    reportedBy: User
    start: string
    end: string
    status: string
    selfCert: string
    type: string
}

const HrSickness = ({ setSelected, editSick, deleteSickness, updateSicknessSelection, handleEditClose, bankHolidays, viewing, handleClose, allSickness, checkThreeSick, threeSick = false, user, createSicknessEvent, allEmployees }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [startDate, setStartDate] = useState<Date | null>()
    const [type, setType] = useState<string>("")
    const [reason, setReason] = useState<string>("")
    const [raisedBy, setRaisedBy] = useState<User>()
    const [reportedToo, setReportedToo] = useState<User>()
    const [selectedSickness, setSelectedSickness] = useState<Sickness>()

    const [editStart, setEditStart] = useState<Date | null>()
    const [editEnd, setEditEnd] = useState<Date | null>()
    const [editReason, setEditReason] = useState<string>("")
    const [editStatus, setEditStatus] = useState<string>("")
    const [editSelfCert, setEditSelfCert] = useState<string>("")
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'start',
            sort: 'desc', // or 'desc'
        },
    ]);

    const calcWeekDays = (firstDate: Date, secondDate: Date) => {
        var lastDay = moment(secondDate);
        var firstDay = moment(firstDate);
        let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 -
            (firstDay.day() - lastDay.day()) * 2) / 7;

        if (lastDay.day() == 6) calcBusinessDays--;//SAT
        if (firstDay.day() == 0) calcBusinessDays--;//SUN

        return calcBusinessDays;
    }

    const calcBankHolidays = (firstDate: Date, secondDate: Date) => {
        let total = 0
        bankHolidays.map((bank) => {
            const hol = moment(new Date(bank.start))

            if (hol.isBetween(moment(firstDate), moment(secondDate))) {
                total = total + 1
            }
        })
        return total
    }


    const rows: GridRowsProp = allSickness.map((sick) => {
        return (
            {
                id: sick.id,
                name: sick.user.name,
                reason: sick.reason,
                reportedBy: sick.reportedBy.name,
                start: moment(new Date(sick.start)).format("DD/MM/yyyy"),
                end: sick.end === "Invalid date" ? "" : moment(new Date(sick.end)).format("DD/MM/yyyy"),
                days: calcWeekDays(new Date(sick.start), new Date(sick.end)) - calcBankHolidays(new Date(sick.start), new Date(sick.end)) - 1 || "Awaiting return",
                status: <Status text={sick.status} />,
                selfCert: <Status text={sick.selfCert} />,
                type: <Status text={sick.selfCert === "Awaiting Self-Cert" ? "Unpaid Sickness" : sick.type} />
            }
        )
    })

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Full Name', width: 200 },
        {
            field: 'status', headerName: 'Status', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        {
            field: 'selfCert', headerName: 'Self-Cert Completed', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        {
            field: 'type', headerName: 'Paid/Unpaid', width: 200,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <strong>
                    <Status text={params.value} />
                </strong>
            ),
            valueGetter: (params) => params.value.props.text
        },
        { field: 'reason', headerName: 'Reason for Absence', width: 400 },
        { field: 'days', headerName: 'Days Off', width: 200 },
        {
            field: 'start', headerName: 'Start Date', width: 200,
            valueGetter: (params) => moment(params.row.start, 'DD/MM/YYYY').format('DD/MM/YYYY'), // Parse date with Moment.js
            sortComparator: (v1, v2, param1, param2) => { // Custom comparator for sorting dates
                const date1 = moment(v1, 'DD/MM/YYYY');
                const date2 = moment(v2, 'DD/MM/YYYY');

                if (date1.isBefore(date2)) {
                    return -1;
                }
                if (date1.isAfter(date2)) {
                    return 1;
                }
                return 0;
            },
        },
        { field: 'end', headerName: 'Return Date', width: 200 },
        { field: 'reportedBy', headerName: 'Reported By', width: 200 },
        
    ];

    useEffect(() => {
        updateSicknessSelection(selectionModel)
        if (selectionModel.length === 1) {
            const sel = allSickness.filter((a) => a.id === selectionModel[0])
            console.log(sel)
            if (sel.length > 0) {
                setSelectedSickness(sel[0])
                setEditReason(sel[0].reason)
                setEditSelfCert(sel[0].selfCert)
                setEditStatus(sel[0].status)
                setEditStart(new Date(sel[0].start))
                if (sel[0].end !== "Invalid date" && sel[0].end !== "") {
                    setEditEnd(new Date(sel[0].end))
                } else {
                    setEditEnd(null)
                }
            }
        }
    }, [selectionModel])

    useEffect(() => {
        if (raisedBy && raisedBy.email !== "") {
            checkThreeSick(raisedBy)
        }
    }, [raisedBy])

    const isWithinLast365Days = (startDate: string): boolean => {
        const start = moment(startDate);
        const today = moment();
        const diffInDays = today.diff(start, 'days');
        return diffInDays <= 365;
    };

    const calculateTotalPaidSicknessDays = (sicknessArray: Sickness[]): number => {
        const today = moment();
        let totalDays = 0;

        sicknessArray.forEach(sickness => {
            if (
                sickness.type === "Paid Sickness" &&
                isWithinLast365Days(sickness.start) &&
                raisedBy?.email.toLowerCase() === sickness.user.email.toLowerCase()
            ) {
                const start = moment(sickness.start);
                const end = moment(sickness.end);
                const days = end.diff(start, 'days') + 1; // Add 1 to include both start and end days
                totalDays += days;
            }
        });

        return totalDays;
    };

    const createSickness = () => {
        if (raisedBy && reportedToo) {
            const rb: User = {
                email: reportedToo?.email || "",
                name: reportedToo?.name || "",
                id: reportedToo?.id || "",
            };

            // Determine the type based on paidSicknessCount
            const paidSicknessCount = allSickness.filter(sickness =>
                sickness.user.email.toLowerCase() === raisedBy.email.toLowerCase() &&
                sickness.type === "Paid Sickness" &&
                isWithinLast365Days(sickness.start)
            ).length;

            const type = (paidSicknessCount < 3 && calculateTotalPaidSicknessDays(allSickness) < 40) ? "Paid Sickness" : "Unpaid Sickness";

            // Create the sickness object
            const temp: Sickness = {
                start: startDate?.toString() || "",
                id: uuidv4(),
                reason: reason,
                status: "Absent",
                end: "",
                selfCert: "Awaiting Self-Cert",
                type: type,
                user: raisedBy,
                reportedBy: rb
            };

            // Create the sickness event
            createSicknessEvent(temp);
        }
        handleClose();
    };

    const editSicknessEvent = () => {
        if (selectedSickness) {
            // Create the sickness object
            const temp: Sickness = {
                start: editStart?.toString() || "",
                id: selectedSickness?.id,
                reason: editReason,
                status: editStatus,
                end: editEnd?.toString() || "",
                selfCert: editSelfCert,
                type: selectedSickness.type,
                user: selectedSickness.user,
                reportedBy: selectedSickness.reportedBy
            };

            // Create the sickness event
            createSicknessEvent(temp);
            handleEditClose()
        }
    }

    useEffect(() => {

        if (selectionModel.length > 0) {
            setSelected(true)
        }
        if (selectionModel.length === 0) {
            setSelected(false)
        }
    }, [selectionModel])

    return (
        <div className='pb-7'>
            <DataGrid
                checkboxSelection
                rows={rows}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                sortingOrder={['asc', 'desc']}
                columns={columns}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
            />
            <div>
                <Modal show={editSick} onHide={handleEditClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="font-bold">Edit Sickness</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="form-label">Start Date:</p>
                        <Form.Group controlId="raiseTicket.summary">
                            <ReactDatePicker
                                dateFormat={"dd/MM/yyyy"}
                                wrapperClassName='w-100'
                                className="form-control w-100 mb-2"
                                calendarClassName='w-100'
                                selected={editStart}
                                placeholderText="Select a date..."
                                onChange={(date) => setEditStart(date)}
                            />
                        </Form.Group>
                        <p className="form-label">End Date:</p>
                        <Form.Group controlId="raiseTicket.summary">
                            <ReactDatePicker
                                dateFormat={"dd/MM/yyyy"}
                                wrapperClassName='w-100'
                                className="form-control w-100 mb-2"
                                calendarClassName='w-100'
                                selected={editEnd}
                                placeholderText="Select a date..."
                                onChange={(date) => setEditEnd(date)}
                            />
                        </Form.Group>
                        <p className="form-label">Reason:</p>
                        <Form.Group className='mb-2'>
                            <Form.Control
                                value={editReason}
                                onChange={(value) => setEditReason(value.currentTarget.value)}
                                placeholder="Enter reason..." />
                        </Form.Group>
                        <p className="form-label">Status:</p>
                        <Form.Group className='mb-2' controlId="raiseTicket.summary">
                            <Form.Select value={editStatus} onChange={(e) => setEditStatus(e.currentTarget.value)}>
                                <option>Select self certification status...</option>
                                <option value="Absent">Absent</option>
                                <option value="Returned">Returned</option>
                            </Form.Select>
                        </Form.Group>
                        <p className="form-label">Self Certification:</p>
                        <Form.Group controlId="raiseTicket.summary">
                            <Form.Select value={editSelfCert} onChange={(e) => setEditSelfCert(e.currentTarget.value)}>
                                <option>Select self certification status...</option>
                                <option value="Awaiting Self-Cert">Awaiting Self-Cert</option>
                                <option value="Completed">Completed</option>
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-end">
                            {selectedSickness &&
                            <Button onClick={() => {
                                handleEditClose()
                                deleteSickness(selectedSickness?.id)}} className="buttonHS">Delete Sickness</Button>
                            }
                            <Button onClick={() => editSicknessEvent()} className="buttonHS w-fc ml-2">Save Sickness</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={viewing} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="font-bold">Register a sickness</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3 mt-2">
                            <Col>
                                <p className="form-label">Employee:</p>
                                <Form.Group className='mb-2' controlId="raiseTicket.summary">
                                    <Typeahead
                                        placeholder='Search for user...'
                                        labelKey="fullName"
                                        onChange={(selected) => {
                                            //@ts-ignore
                                            setRaisedBy({ email: selected[0].email, name: selected[0].fullName, id: "" })
                                        }}
                                        options={allEmployees}
                                    />
                                </Form.Group>
                                <p className="form-label">Reported by:</p>
                                <Form.Group className='mb-2' controlId="raiseTicket.summary">
                                    <Typeahead
                                        placeholder='Search for user...'
                                        labelKey="fullName"
                                        onChange={(selected) => {
                                            //@ts-ignore
                                            setReportedToo({ email: selected[0].email, name: selected[0].fullName, id: "" })
                                        }}
                                        options={allEmployees}
                                    />
                                </Form.Group>
                                <p className="form-label">Start Date:</p>
                                <Form.Group controlId="raiseTicket.summary">
                                    <ReactDatePicker
                                        dateFormat={"dd/MM/yyyy"}
                                        wrapperClassName='w-100'
                                        className="form-control w-100 mb-2"
                                        calendarClassName='w-100'
                                        selected={startDate}
                                        placeholderText="Select a date..."
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => createSickness()} className="buttonHS w-fc">Submit Request</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default HrSickness